<template>
  <v-main>
    <v-container fluid :class="isFull ? '' : 'px-4'">
      <router-view>this is main</router-view>
    </v-container>
  </v-main>
</template>
<script>
import { mapState } from 'vuex';
export default {
  components: {},
  computed: {
    ...mapState({
      isFull: (state) => state.screenStore.isFull,
    }),
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-main__wrap {
  background-color: #f7f7f7;
}
</style>
